<template>
	<div class="container" :style="{ 'background-image': containerBg }">
		<!-- <van-notice-bar mode="link" v-if="expiration_time">{{expiration_time}}</van-notice-bar> -->
		<div class="content"
			v-bind:style="{ height: contentHeight, 'margin-top': statusBarHeight, 'padding-top': contentPaddingTop }">
			<div class="level1">
				<van-swipe class="swiper" indicator-dots="true" duration="200" :width="swipeItemWidth">
					<van-swipe-item class="swiper-item" v-for="(ll, ii) in listArray" :key="ii">
						<div class="level1-item" :key="index" v-for="(item, index) in ll"
							@click="cellclicked(item, index)">
							<tips :value="item.tips"></tips>
							<img :src="item.img" mode="aspectFill"></img>
							<div class="level1-item-title">{{ item.name }}</div>
							<div class="level1-item-subtitle">{{ item.subtitle }}</div>
						</div>
					</van-swipe-item>
				</van-swipe>
			</div>
			<div class="content-scroll">

				<div class="menu" v-if="list2 && list2.length">
					<div class="menu-left">
						<div class="current-item">每日必点</div>
					</div>
				</div>
				<div class="level2">
					<div class="level2-right" :key="index" v-for="(item, index) in list2"
						@click="cellclicked(item, index)">
						<img :src="item.img" mode="aspectFill"></img>
					</div>
				</div>
				<div class="kfc" v-if="kfc.menuVoList.length && kfcitem">
					<div class="kfc-top">
						<div class="kfc-top-title">汉堡炸鸡</div>
						<div class="kfc-top-subtitle">肯德基低至6折</div>
					</div>
					<div class="kfc-content">
						<div class="kfc-content-item" v-for="(item, index) in kfc.menuVoList" :key="index"
							@click="kfcclicked()">
							<div class="kfc-content-item-image">
								<img :src="item.imageUrl" mode="aspectFill"></img>

							</div>
							<!-- <div class="kfc-content-item-image-distance">{{coverDistance(kfc.store.distance)}}
							</div>
							<div class="kfc-content-item-image-position">{{kfc.store.address.substr(0,8)}}</div> -->
							<div class="kfc-content-item-name">{{ item.nameCn.substr(0, 6) }}</div>
							<div class="kfc-content-item-price">
								立减{{ Math.round((item.show_price_original - item.show_price_discount) * 10) / 10 }}元
							</div>
						</div>
					</div>
				</div>
				<div class="menu2" v-if="filmList.length" v-show="cardType != 'hjzaog'">
					<div class="menu-left">
						<div class="current-item">热门电影</div>
					</div>

					<div class="menu-right">
						<div @click="movileclick()">查看更多 ></div>
					</div>
				</div>
				<div class="movies" v-show="cardType != 'hjzaog'">
					<div class="movies-item" v-for="(item, index) in filmList" :key="index" @click="movileclick()">
						<div class="movies-item-1">
							<img :src="item.pic" mode="aspectFill"></img>
						</div>
						<div class="movies-item-2">
							<div class="movies-item-2-1">{{ item.name.substr(0, 12) }}</div>
							<div class="movies-item-2-2">
								<div>评分</div>
								<div class="movies-item-2-2-score">
									{{ (item.grade && item.grade != '0.0') ? item.grade : "暂无评分" }}
								</div>
							</div>
							<div class="movies-item-2-3">主演：{{ item.cast.substr(0, 16) }}</div>
							<div class="movies-item-2-4">{{ item.publishTime.split(' ')[0] }} 上映</div>
						</div>
						<div class="movies-item-3">
							<!-- <div>购票</div> -->
						</div>
					</div>

				</div>
			</div>
		</div>
		<van-popup v-model="showhydlg">
			<div class="hydlgcontent">
				<div class='hydlgclose' @click="hydlgclose">
					<img src="http://img.123zhs.com/h5/hy/close-circle-fill.png" />
				</div>
				<div @click="hydlgclick">
					<img class="hydlg" :src="hydlgbg" />
				</div>
			</div>
		</van-popup>

		<!-- <div class="more">
			<img v-if="kefu" @click="handlekefu" class="kefustyle" src="https://img.123zhs.com/mp-qyw/kefu.png"></img>
		</div> -->
		<!-- <logindlg ref="login_dlg"></logindlg> -->
	</div>
</template>
<script>
import Tips from '../components/Tips.vue'
import {
	mapActions,
	mapState,
	store
} from 'vuex'
import help from '../utils/Help'
import hhstore from '../utils/HHStore'
import urlParams from '../utils/UrlParams'
import wxutils from '../utils/Wx'
import {
	Toast,
	Swipe,
	SwipeItem,
	NoticeBar,
	Dialog,
	Form,
	Sticky,
	Button,
	Popup,
	Field
} from 'vant';
import Vue from 'vue';

import aes from '../utils/Aes'
var md5 = require('md5')

Vue.use(Form);
Vue.use(Field);
Vue.use(NoticeBar);
Vue.use(Popup);

export default {
	name: 'list',
	components: {
		[Dialog.Component.name]: Dialog.Component,
		[Swipe.name]: Swipe,
		[Sticky.name]: Sticky,
		[NoticeBar.name]: NoticeBar,
		[Button.name]: Button,
		[SwipeItem.name]: SwipeItem,
		'tips': Tips
	},
	data: function () {
		return {
			hydlgbg: "http://img.123zhs.com/h5/hy/hytc3.png",
			containerBg: "url('https://img.123zhs.com/mp-qyw/ty_top.png?x-oss-process=image/resize,w_752/quality,q_80')",
			list: [],
			list2: [],
			kfc: {
				menuVoList: [],
				store: {
					address: ""
				}
			},
			kfcitem: null,
			filmList: [],
			expiration_time: null,

			contentHeight: "calc(100vh - 140px)",
			statusBarHeight: "49",
			contentPaddingTop: "140px",
			info: null,
			cardType: null,
			logo: "",
			showhydlg: false,
		}
	},
	props: {

	},
	beforeMount() {

	},
	async mounted() {

		this.$store.commit('SET_HOMEPAGE', "/products")


		// 获取卡类型，存本地，每个网络请求都带上这个参数
		this.cardType = urlParams.get('ch')
		if (urlParams.get('pos')) {
			hhstore.set('pos', urlParams.get('pos'))
		}

		if (this.cardType) {
			hhstore.set('card_type', this.cardType)
		} else {
			this.cardType = hhstore.get('card_type')
		}
		hhstore.set('homepage', "/products")
		hhstore.set('login_back', "/products") // 先设置如果未登录这个跳转的是这个页面

		if (this.cardType == 'elrywo') {
			this.hydlgbg = 'http://img.123zhs.com/h5/hy/elrywo.png'
		} else {
			this.hydlgbg = 'http://img.123zhs.com/h5/hy/hytc3.png'
		}

		if (this.cardType == 'Q8USdphPCMw2')
			this.containerBg =
				'url("http://img.123zhs.com/h5/woyaodazhe.jpeg?x-oss-process=image/resize,w_752/quality,q_80")'
		if (this.cardType == 'xiaodu' || this.cardType == 'nnl')
			this.containerBg =
				'url("http://img.123zhs.com/mp-qyw/img/main/top.png?x-oss-process=image/resize,w_750/quality,q_80")'
		// if (this.cardType == 'suba') {
		// 	window.location.href = window.location.origin + "/products2" + window.location.search
		// 	return;
		// }
		await this.homeindex()

		// 如果url里带了phone参数
		var phone = urlParams.get('phone')
		if (phone && phone != hhstore.get("phone")) {
			const ret = await this.quicklogin(phone)
			if (!ret) {
				return
			}
		}

		// if (!this.nnl && !hhstore.get("token")) {
		// 	// 微信登录失败，返回注册页面
		// 	hhstore.set('login_back', "/products")
		// 	this.$router.push('/register')
		// 	return;
		// }

		this.userinfo()

		this.getKfc()
		this.remenFilm()

	},
	computed: {
		...mapState(['nnl', 'kefu', 'hy']),
		listArray() {
			if (this.list) {
				const arr = this.chunk(this.list, 15)
				return arr
			} else {
				return []
			}
		},
		swipeItemWidth() {
			return document.body.clientWidth
		}
	},
	methods: {
		...mapActions(['post', 'syncpost']),
		chunk(arr, size) {
			var objArr = new Array();
			var index = 0;
			var objArrLen = arr.length / size;
			for (var i = 0; i < objArrLen; i++) {
				var arrTemp = new Array();
				for (var j = 0; j < size; j++) {
					arrTemp[j] = arr[index++];
					if (index == arr.length) {
						break;
					}
				}
				objArr[i] = arrTemp;
			}
			return objArr;
		},

		async getKfc() {
			try {
				var location = await this.getLocation()
			} catch (e) {
				location = {
					latitude: "39.929986",
					longitude: "116.412893",
				}
			}

			console.info(location)
			this.post({
				url: "kfc/cb_nearby_products",
				data: {
					appid: 100024,
					latitude: location.latitude,
					longitude: location.longitude,
					// latitude: "39.929986",
					// longitude: "116.412893",
				},
				success: (data) => {
					this.kfc = data
				}
			});
		},
		movileclick() {
			// 循环list找到电影票item
			for (let item of this.list) {
				if (item.name === '电影票') {
					this.cellclicked(item, 0)
					break
				}
			}
		},
		kfcclicked() {
			this.cellclicked(this.kfcitem, 0)
		},
		remenFilm() {

			const homeFilmList = hhstore.get('homeFilmList');
			if (homeFilmList) {
				this.filmList = JSON.parse(homeFilmList)
			}

			this.post({
				url: "film/remen",
				data: {},
				success: (data) => {
					hhstore.set('homeFilmList', JSON.stringify(data));
					this.filmList = data
				}
			});

		},
		coverDistance(distance) {
			if (distance > 1000) {
				return Math.round(distance / 100) / 10 + '公里'
			}
			return distance + '米'
		},
		getLocation() {
			return new Promise((resolve, reject) => {
				const UA = navigator.userAgent.toLowerCase()
				navigator.geolocation.getCurrentPosition(
					(res) => {
						resolve(res.coords)
					},
					(error) => {
						console.info(error)
						reject(error)
					}
				)
			})
		},
		userinfo() {
			var self = this;
			var uid = hhstore.get("uid");
			var token = hhstore.get("token");

			if (!uid) {
				return;
			}

			this.post({
				url: "user/info",
				data: {
					"uid": uid,
					"token": token
				},
				success: function (data) {
					self.info = data;
					hhstore.set("phone", data.phone)
					self.expiration_time = "您的权益有效期至：" + data.expiration_time.split(' ')[0]
				},
				error: function (code, err) {
					if (code == 92834 || code == 10002 || code == 10003) {
						hhstore.del("uid")
						hhstore.del("phone")
						hhstore.del("token")
						// if (!self.nnl) {
						// 	hhstore.set('login_back', "/products" + window.location.search)
						// 	self.$router.push('/register')
						// }
					} else {
						Toast.fail(err);
					}

				}
			});
		},
		async quicklogin(phone) {
			try {
				let data = await this.syncpost({
					url: "user/quick_register",
					data: {
						"phone": phone,
					}
				});

				hhstore.set("uid", data.uid)
				hhstore.set("token", data.token)
				hhstore.set("phone", phone)
				return true
			} catch (e) {
				Toast.fail(e.message);
				return false
			}
		},
		async homeindex() {

			// 优先使用缓存
			try {
				this.list = JSON.parse(hhstore.get('product_list'))
			} catch (e) { }

			try {
				let uid = hhstore.get("uid");

				let data = await this.syncpost({
					url: "home2/index",
					data: {
						uid: uid
					}
				});
				this.logo = data.logo
				this.list = data.list
				this.list2 = data.list2

				this.$store.commit('SET_KEFU', data.kefu)
				hhstore.set('kefu', data.kefu)

				for (let item of data.list) {
					if (item.name == '肯德基') {
						this.kfcitem = item
					}
				}

				hhstore.set('product_list', JSON.stringify(data.list))
				this.$store.commit('SET_NNL', data.nnl)
				this.$store.commit('SET_HY', data.hy)

				if (data.hy && !this.nnl && !hhstore.get("token")) {
					this.showhydlg = true
				}
			} catch (e) {
				console.info(e)
			}
		},
		cellclicked(item, index) {
			let uid = hhstore.get("uid");
			let token = hhstore.get("token");
			let phone = hhstore.get("phone");

			if (!token) {
				Dialog.confirm({
					title: '提示',
					message: this.nnl ? '您还没有登录，立即去登录？' : '您还没有激活权益，立即去激活权益？',
				})
					.then(() => {
						hhstore.set('login_back', "/products")
						if (this.nnl) {
							this.$router.push('/login')
						} else {
							this.$router.push('/register')
						}
					})
				return;
			}


			if (item.type == "H5") {
				this.launchH5(item)
			} else if (item.type == "mthuodong") {
				this.launchmeituanhuodong(item);
			} else if (item.type == "kfc") {
				this.launchkfc(item, phone);
			} else if (item.type == "kfczj") {
				this.launchkfczj(item, phone);
			} else if (item.type == "mdl") {
				this.launchmdl(item, phone);
			} else if (item.type == "jy" || item.type == "xjjy") {
				this.launchjy(item, phone, token, uid);
			} else if (item.type == "xbkzx") {
				this.launchxbkzx(item, phone);
			} else if (item.type == "xbkzx_hw") {
				this.launchxbkzx_hw(item, phone);
			} else if (item.type == "bgy") {
				this.launchbgy(item, phone);
			} else if (item.type == "nx") {
				this.launchnx(item, phone);
			} else if (item.type == "nxzx") {
				this.launchnxzx(item, phone);
			} else if (item.type == "nxzx_hw") {
				this.launchnxzx_hw(item, phone);
			} else if (item.type == "rx") {
				this.launchrxzx(item, phone);
			} else if (item.type == "xc") {
				this.launchxc(item, phone);
			} else if (item.type == "dy") {
				this.launchdy(item, phone);
			} else if (item.type == "dyph") { // 票虎电影票
				this.launchdyph(item, phone);
			} else if (item.type == "dy2") {
				this.launchdy2(item, phone);
			} else if (item.type.substring(0, 4) == "lxsh") { //乐享相关的肯德基麦当劳，必胜客等等
				this.launchlxsh(item, phone, item.type.substring(4));
			} else if (item.type == "bsk") {
				this.launchbsk(item, phone);
			} else if (item.type == "didi") {
				this.launchdidi(item);
			} else if (item.type == "jiayou") {
				this.launchjiayou(item, uid, token);
			} else if (item.type == "didijiayou") {
				this.launchdidijiayou(item);
			} else if (item.type == "xiaozhu") {
				this.launchxiaozhu(item);
			} else if (item.type == "list") {
				this.launchlist(item);
			}
		},
		launchH5(item) {
			help.click_log(item.name, item.src)
			window.location.href = item.src;
		},
		launchmeituanhuodong() {
			this.$router.push('/meituan')
		},
		launchlist(item) {
			hhstore.set('yinge', JSON.stringify(item))
			window.location.href = "/yinge"
		},
		launchjy(item, phone, token, uid) {
			this.post({
				url: "xjjy/geturl",
				data: {
					"uid": uid,
					"token": token
				},
				success: function (data) {
					help.click_log(item.name, data)
					window.location.href = data;
				},
				error: function (error) {
					console.info(error)
				}
			});
		},
		launchkfc(item, phone) {
			const kfcurl = item.src + "&phone=" + phone;
			help.click_log(item.name, kfcurl)
			window.location.href = kfcurl;
		},
		launchkfczj(item, phone) {
			var url =
				"http://kfc.quhaodian.cn/diancan/new.html?v=1.1&platmnt=gh_90fb3ad61e18&notify_url=http://api.zhsyh.cn/callback/kfc"

			const kfcurl = url + "&buyer_phone=" + phone;
			help.click_log(item.name, kfcurl)
			window.location.href = kfcurl;
		},
		launchbsk(item, phone) {
			// let sign = md5(phone + "8BE8WHUqUvrf3m7Y64WWgGAjRZzt")
			// let url = "http://zd.taillessbear.com/zd/bsk.php?channelid=102106&appuid=" + phone + "&sign=" + sign
			// window.location.href = url;
			let urlparams = this.hdzsign({
				"distributorCode": "1363",
				"platformuid": phone,
			}, "cd7b97c56ba84e5485737404e7728577");
			console.info(urlparams)
			let url = "https://ptlxhy.cn/#/independentPizza?" + urlparams;
			help.click_log(item.name, url)
			window.location.href = url;

		},
		launchjiayou(item, uid, token) {
			this.post({
				url: "xjjy/geturl",
				data: {
					"uid": uid,
					"token": token
				},
				success: function (data) {
					help.click_log(item.name, data)
					window.location.href = data;
				},
				error: function (error) {
					Toast.fail(error);
				}
			});
		},
		async launchdidi(item) {
			Toast.loading({
				message: '加载中...',
				forbidClick: true,
				duration: 0
			});

			try {
				// h5的
				let data = await this.syncpost({
					url: "xjjy/generate_link_url",
					data: {
						"pid": item.pid,
						"type": "h5",
					},
				});

				Toast.clear()
				help.click_log(item.name, data.link)
				window.location.href = data.link;
			} catch (e) {
				Toast.fail(e.message);
			}
		},
		//花小猪
		async launchxiaozhu(item) {
			Toast.loading({
				message: '加载中...',
				forbidClick: true,
				duration: 0
			});

			try {
				// h5的
				let data = await this.syncpost({
					url: "xjjy/generate_xiaozhu_url",
					data: {
						"pid": item.pid,
						"type": "h5",
					},
				});

				Toast.clear()
				help.click_log(item.name, data.link)
				window.location.href = data.link;
			} catch (e) {
				Toast.fail(e.message);
			}
		},
		async launchdidijiayou(item) {
			Toast.loading({
				message: '加载中...',
				forbidClick: true,
				duration: 0
			});

			try {
				// h5的
				let data = await this.syncpost({
					url: "xjjy/generate_jiayou_url",
					data: {
						"pid": item.pid,
						"type": "h5",
					},
				});

				Toast.clear()
				help.click_log(item.name, data.link)
				window.location.href = data.link;
			} catch (e) {
				Toast.fail(e.message);
			}
		},
		launchxbkzx(item, phone) {
			let url = "https://ptlxhy.cn/#/independentXbk?distributorCode=1363&flag=independent"
			help.click_log(item.name, url)
			window.location.href = url
		},
		launchxbkzx_hw(item, phone) {
			let urlparams = this.hwsign({
				"shareCode": "6492aNymP",
				"userId": phone,
			}, "408957BF28AD4A84B359D45D0DDCE8EE");
			let url = "https://ot.jfshou.cn/sbkplus/auth/index?" + urlparams;
			help.click_log(item.name, url)
			window.location.href = url;
		},
		launchbgy(item, phone) {
			let urlparams = this.hwsign({
				"shareCode": "6492aNymP",
				"userId": phone,
			}, "408957BF28AD4A84B359D45D0DDCE8EE");
			let url = "https://ot.jfshou.cn/privilege-api/pagoda/auth/index?" + urlparams;
			help.click_log(item.name, url)
			window.location.href = url;
		},
		launchmdl(item, phone) {
			let timestamp = new Date().getTime();
			let shareCode = "6492aNymP";
			let s = "408957BF28AD4A84B359D45D0DDCE8EE";

			let beforesign = "shareCode=" + shareCode + "&timestamp=" + timestamp + "&userId=" + phone +
				"&secretKey=" + s;
			console.info(beforesign);

			let sign = md5(beforesign).toUpperCase();
			console.info(sign);

			let urlparams = "share_code=" + shareCode + "&timestamp=" + timestamp + "&user_id=" + phone + "&sign=" +
				sign;
			console.info(urlparams);

			const mdlurl = "https://ot.jfshou.cn/api/mcdonald/entrance?" + urlparams;
			console.info(mdlurl);
			help.click_log(item.name, mdlurl)
			window.location.href = mdlurl;
		},
		launchnxzx(item, phone) {
			let url = "https://ptlxhy.cn/#/independentNx?distributorCode=1363&flag=independent"
			help.click_log(item.name, url)
			window.location.href = url
		},
		launchnxzx_hw(item, phone) {
			let urlparams = this.hwsign({
				"shareCode": "6492aNymP",
				"userId": phone,
			}, "408957BF28AD4A84B359D45D0DDCE8EE");
			let url = "https://ot.jfshou.cn/api/nayuki/entrance?" + urlparams;
			help.click_log(item.name, url)
			window.location.href = url;
		},
		launchnx(item, phone) {
			let urlparams = this.hwsign({
				"id": "94",
				"machine_code": phone,
				"single_sign": "true",
				"distribution_code": "yF4FMa4",
			}, "T4KHEanXQcbf3sRmzZcTMZyetS4mZ3QG");
			let url = "https://tq.jfshou.cn/seller/videoApp/appVideo?" + urlparams;
			help.click_log(item.name, url)
			window.location.href = url;
		},
		launchxc(item, phone) {
			let urlparams = this.hwsign({
				"shareCode": "6492aNymP",
				"userId": phone,
			}, "408957BF28AD4A84B359D45D0DDCE8EE");
			let url = "https://ot.jfshou.cn/privilege-api/heytea/auth/index?" + urlparams;
			help.click_log(item.name, url)
			window.location.href = url;
		},
		launchrxzx(item, phone) {
			let urlparams = this.hwsign({
				"shareCode": "647J8fs9s",
				"userId": phone,
			}, "4607280171F242359D12205473FD0FF5");
			let url = "https://ot.jfshou.cn/privilege-api/luckin/auth/index?" + urlparams;
			help.click_log(item.name, url)
			window.location.href = url;
		},
		launchdy(item, phone) {
			let url = "https://ptlxhy.cn/#/independentFilm?distributorCode=1363&flag=independent"
			help.click_log(item.name, url)
			window.location.href = url;
		},
		launchdyph(item, phone) {
			let url = "https://film.123hzd.com/?phone=" + phone
			help.click_log(item.name, url)
			window.location.href = url;
		},
		launchdy2(item, phone) {
			let urlparams = this.hwsign({
				"shareCode": "6492aNymP",
				"userId": phone,
			}, "408957BF28AD4A84B359D45D0DDCE8EE");
			let url = "https://ot.jfshou.cn/ticket/auth/index?" + urlparams;
			window.location.href = url;
		},
		async launchlxsh(item, phone, type) {
			let url = await this.syncpost({
				url: "yizhifu/lxurl",
				data: {
					platformuid: phone,
					type: type,
					card_type: this.cardType
				}
			});
			help.click_log(item.name, url)
			window.location.href = url;
		},
		// 海威的签名
		hwsign(data, secretKey) {
			data["timestamp"] = new Date().getTime();

			let beforesign = this.jsonSort(data) + "&secretKey=" + secretKey
			console.info(beforesign);

			let sign = md5(beforesign).toUpperCase();
			console.info(sign);

			data['sign'] = sign;
			let urlparams = this.jsonSort(data)
			console.info(urlparams);

			return urlparams
		},
		jsonSort(jsonObj) {
			let arr = [];
			for (var key in jsonObj) {
				arr.push(key)
			}
			arr.sort();
			let str = '';
			for (var i in arr) {
				str += arr[i] + "=" + jsonObj[arr[i]] + "&"
			}
			return str.substr(0, str.length - 1)
		},
		handlekefu() {
			window.location.href = this.kefu
		},
		hdzsign(data, secretKey) {
			data["time"] = new Date().getTime();

			let beforesign = this.joinmap(data) + secretKey
			console.info(beforesign);

			let sign = md5(beforesign);
			console.info(sign);

			data['sign'] = sign;
			let urlparams = this.jsonSort(data, false)
			console.info(urlparams);

			return urlparams
		},
		// 海威的签名字符串链接
		joinmap(jsonObj) {
			let arr = [];
			for (var key in jsonObj) {
				arr.push(key)
			}
			arr.sort();
			let str = '';
			for (var i in arr) {
				str += jsonObj[arr[i]]
			}
			return str
		},
		hydlgclick() {
			this.$router.push('/activate')
		},
		hydlgclose() {
			this.showhydlg = false
		}

	}
}
</script>
<style scoped>
.container {
	overflow: hidden;
	background-color: #F8F8F8;
	width: 100vw;
	height: 100vh;
	-moz-background-size: 100% auto;
	background-size: 100% auto;
	background-repeat: no-repeat;
}

.level1 {
	margin-left: 0.75rem;
	/* width: 22.6125rem; */
	width: 90.45vw;
	padding-left: 1.41vw;
	padding-right: 1.41vw;
	padding-top: 0.40625rem;
	padding-bottom: 0.625rem;
	background-color: #FFFFFF;
	border-radius: 0.625rem;
	overflow: hidden;
	border-color: #F3f3f3;
	border-style: solid;
	border-width: 1px;
}

.level1-item {
	margin-top: 1.21875rem;
	margin-left: 0.1675rem;
	/* width: 4.2625rem; */
	width: 17.05vw;
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: center;
}

.level1-item img {
	align-self: center;
	/* width: 2.6875rem;
		height: 2.6875rem; */
	width: 10.8vw;
	height: 10.8vw;
	border-radius: 1.34375rem;

}

.level1-item-title {
	align-self: center;
	/* font-size: 0.875rem; */
	font-size: 3.5vw;
	margin-top: 0.65625rem;
	color: #333333;
}

.level1-item-subtitle {
	align-self: center;
	/* font-size: 0.75rem; */
	font-size: 2.8vw;
	color: #999999;
	/* font-weight: 350; */
}

.level2 {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-top: 0.625rem;
}

.level2-right {
	margin-right: 0.625rem;
}

.level2 img {
	width: 45vw;
	height: 5.3rem;
	border-radius: 0.3125rem;
}

.kfc {
	width: 23rem;
	border-radius: 0.46875rem;
	/* box-shadow: 0px 0px 6px #e1e1e1; */
	border-color: #F3f3f3;
	border-style: solid;
	border-width: 1px;
	overflow: hidden;
}

.kfc-top {
	width: 100%;
	height: 2.8125rem;
	background: -webkit-linear-gradient(left, #F7D7A8, #F6E5C8);
	background: -moz-linear-gradient(left, #F7D7A8, #F6E5C8);
	overflow: hidden;
	display: flex;
	align-items: center;
}

.kfc-top-title {
	color: #333333;
	font-size: 0.9375rem;
	color: #333333;
	font-weight: bold;
}

.kfc-top-subtitle {
	color: #555555;
	font-size: 0.75rem;
	margin-left: 0.46875rem;
}

.kfc-content {
	display: flex;
	padding-left: 0.66875rem;
	background-color: white;
	padding-top: 0.5rem;
}

.kfc-content-item:first-child {
	margin-left: 0rem !important;
}

.kfc-content-item {
	position: relative;
	margin-left: 0.86875rem;
}

.kfc-content-item-image {
	position: relative;
	/* background-image: url("http://img.123zhs.com/mp-qyw/kfc_bg.png"); */
	width: 26vw;
	height: 26vw;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}

.kfc-content-item img {
	width: 6.625rem;
	height: 6.625rem;
	object-fit: cover;
}

.kfc-content-item-image-distance {
	position: absolute;
	bottom: 5.0625rem;
	font-size: 0.5625rem;
	background-color: #EA7B36;
	padding-right: 0.3125rem;
	padding-left: 0.15625rem;
	color: white;
	height: 0.9375rem;
	border-radius: 0px 0.46875rem 0.46875rem 0px;
	line-height: 0.9375rem;
}

.kfc-content-item-image-position {
	position: absolute;
	bottom: 3.75rem;
	font-size: 0.5625rem;
	background-color: #EA7B36;
	color: white;
	height: 0.9375rem;
	border-radius: 0px 0.46875rem 0.46875rem 0px;
	padding-right: 0.3125rem;
	padding-left: 0.15625rem;
	line-height: 0.9375rem;
}



.kfc-content-item-name {
	color: #333333;
	font-size: 0.875rem;
	margin-top: 0.40625rem;
	font-weight: bold;
}

.kfc-content-item-price {
	color: #EA3323;
	font-size: 0.75rem;
	margin-top: 0.3125rem;
	margin-bottom: 0.6875rem;
}

.menu {
	display: flex;
	justify-content: space-between;
	padding-right: 0.96875rem;
	align-items: baseline;
	margin-top: 0.46875rem;
}

.menu2 {
	display: flex;
	justify-content: space-between;
	padding-right: 0.96875rem;
	margin-top: 0.25rem;
	align-items: baseline;
	margin-top: 0.9375rem;
}

.menu-left {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 0.75rem;
	color: #555555;
}

.current-item {
	font-size: 0.9375rem;
	color: #333333;
	font-weight: bold;
}

.menu-right {
	font-size: 0.75rem;
	color: #999999;
}

.movies {
	display: flex;
	flex-direction: column;
	padding-top: 0.90625rem;
}

.movies-item {
	margin-bottom: 0.9375rem;
	display: flex;
	height: 5.9rem;
	width: 100%;
	align-items: center;
}

.movies-item-1 img {
	width: 4.16rem;
	height: 5.9rem;
	border-radius: 0.3125rem;
	margin-right: 0.4375rem;
}

.movies-item-2 {
	width: 13.09375rem;
	height: 5.9rem;
	display: flex;
	flex-direction: column;
}

.movies-item-2-1 {
	margin-top: 0.2rem;
	font-weight: bold;
	color: #333333;
	font-size: 0.875rem;
}

.movies-item-2-2 {
	display: flex;
	margin-top: 0.53125rem;
	font-weight: bold;
	color: #999999;
	font-size: 0.6875rem;
}

.movies-item-2-2-score {
	margin-left: 0.3125rem;
	color: #BB4B55;
}

.movies-item-2-3 {
	margin-top: 0.28125rem;
	font-weight: bold;
	color: #999999;
	font-size: 0.6875rem;
}

.movies-item-2-4 {
	margin-top: 0.28125rem;
	font-weight: bold;
	color: #999999;
	font-size: 0.6875rem;
}


.movies-item-3 view {
	text-align: center;
	line-height: 1.4375rem;
	color: white;
	width: 3.28125rem;
	height: 1.4375rem;
	background: -webkit-linear-gradient(left, #F1A256, #EB667F);
	background: -moz-linear-gradient(left, #F1A256, #EB667F);
	font-size: 0.6875rem;
	border-radius: 0.71875rem;
}

.content {
	overflow-y: scroll;
	width: 100vw;
}

.content::-webkit-scrollbar {
	display: none
}

.content-scroll {
	background-color: #F8F8F8;
	padding-left: 0.75rem;
	margin-top: 0.5625rem;
	overflow: hidden;
}

.swiper-item {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	/* align-items: flex-start; */
	align-content: flex-start;
}

.swiper {
	height: 22.1875rem;
}

.more {
	position: absolute;
	right: 2rem;
	bottom: 2rem;
}

.kefustyle {
	width: 3.75rem;
	height: 3.75rem;
}

.hydlg {
	width: 20rem;
	height: 16.67rem;
	display: block;
}

.hydlgcontent {
	position: relative;

}

.hydlgclose {
	position: absolute;
	top: 3px;
	right: 5px;
	/* background-color: red;
		width: 30px;
		height: 30px; */
}

.hydlgclose img {
	width: 30px;
	height: 30px;
}
</style>

<style>
.van-swipe__indicator {
	background-color: #666666;
}

.van-swipe__indicators {
	bottom: 0.325rem;
}

.van-popup {
	background: none;
}
</style>
